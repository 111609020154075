export function setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,showthresholdTargetName,holdthresholdTargetName){

  if(document.getElementById('pagetop')){
    const scrolltopBtn = document.getElementById('pagetop');
    const btnOffsetInt = parseInt(scrolltopBtn.dataset.offsetbottom);
    const elm_holdthresholdTargetName = document.getElementById(holdthresholdTargetName);
    const elm_showthresholdTargetName =  document.getElementById(showthresholdTargetName);


    toggleScrolltopBtn(); //ボタン出現アニメーション

    //クリックイベント追加
    scrolltopBtn.addEventListener( 'click' , doScrolltop );

    //スムーススクロール処理関数
    function doScrolltop(){
      window.scroll({top: 0, behavior: 'smooth'});
    }

    // -------------------
    // ボタン出現アニメーション
    // -------------------

    function toggleScrolltopBtn(){

      if(CURRENT_SC_OFFSET_Y > showthreshold(elm_showthresholdTargetName) && CURRENT_SC_OFFSET_Y < holdthreshold(elm_holdthresholdTargetName)){
        //フィックス判定ON
        scrolltopBtn.classList.add('js_isFixed');
        scrolltopBtn.classList.remove('js_isHold');

      }else	if(CURRENT_SC_OFFSET_Y > showthreshold(elm_showthresholdTargetName) && CURRENT_SC_OFFSET_Y > holdthreshold(elm_holdthresholdTargetName)){
        //ホールド判定ON
        scrolltopBtn.classList.add('js_isHold');
        scrolltopBtn.classList.remove('js_isFixed');

      }else	if(CURRENT_SC_OFFSET_Y < showthreshold(elm_showthresholdTargetName) && CURRENT_SC_OFFSET_Y < holdthreshold(elm_holdthresholdTargetName)){
        //出現閾値に達していない場合
        scrolltopBtn.classList.remove('js_isFixed');
        scrolltopBtn.classList.remove('js_isHold');

      }else{

      }
    }

    // 以下、ボタン出現アニメーション関数内で使用
    // -------------------

    //ボタン出現位置のY座標を出力
    function showthreshold(target){

      if(target){
        let targetOffsetTop = target.offsetTop;
        let targetHeight = target.clientHeight;
        return  targetOffsetTop + targetHeight;
      }else{
        return 0;
      }
    }

    //ボタン固定位置のY座標を出力
    function holdthreshold(target){

      if(target) {

        let targetOffsetTop = target.offsetTop;

        //要素の座標取得位置を上辺ではなく下辺を基準に取りたい場合は targetElm.clientHeight のコメントアウトを解除
        let targetHeight = 0;
        // let targetHeight = targetElm.clientHeight;

        //ボタン要素とターゲット要素のY軸の差分を算出
        let btnH = scrolltopBtn.clientHeight;
        let btnBottomSize = btnOffsetInt; //20
        let btnOffsetY = 0 //ボタンの中心点でホールドさせるには0に設定（この変数はボタンの固定値のオフセットに利用します）
        let btnOffsetBuffer = btnH + btnBottomSize - btnOffsetY;

        return targetOffsetTop + targetHeight - WIN_HEIGHT + btnOffsetBuffer;
      }else{
        return 0;
      }

    }
  }



}
