// ============================================================
// モジュール読み込み
// ============================================================

//import Vue from 'vue';
import * as func from './functions/_function';                        //関数関係
import * as offcanvas from './functions/_offcanvas';                  //オフキャンバス
import 'mobile-detect';                                               //デバイス判定・ブラウザ判定ライブラリ
import * as checkUA from './functions/_checkUA';                      //デバイス判定・ブラウザ判定処理
import * as pagetop from './functions/_pagetop';
import * as loading from './functions/_loading';                      //ローディング
// デフォルトのテーマ
import '@splidejs/splide/css';
// または、そのほかのテーマ
import '@splidejs/splide/css/skyblue';
import '@splidejs/splide/css/sea-green';
import Splide from '@splidejs/splide';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// import  {AutoScroll}  from '@splidejs/splide-extension-auto-scroll';
// import {removeClass} from "../../../../../js/src/lib/dom";

// ============================================================
// 定数
// ============================================================

const BLAKEPOINT_HEADER_SP_MODE = 1124;                               //ヘッダーレスポンシブブレイクポイント
const DEBUG_MODE                = false;                              //デバッグモード

// ============================================================
// グローバル変数
// ============================================================

let CURRENT_SCROLL_POS = 0;
let LAST_SCROLL_POS = 0; //前回のスクロールポジション
let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.scrollX;
let CURRENT_SC_OFFSET_Y       = window.scrollY;
let SCROLLDOWN_FLAG           = true;
let elm_header = document.getElementById('globalHeader');
let elm_wrapper = document.getElementById('wrapper');
let HEADER_H = elm_header.offsetHeight;

//
//
//
//
//
//
//
//
//
//
//
//

// ===============================================================================
// TODO:README
// イベント実行
// 以下のセクションで関数や処理を実行させてください
// ===============================================================================

// ------------------------------------------------------------
// ドキュメント読み込み時（DOMContentLoaded）
// ------------------------------------------------------------



window.addEventListener('DOMContentLoaded',() => {

  checkUA.addUAClass();                                                    // UAおよびデバイスクラス追加処理
  loading.initLoading(DEBUG_MODE);

  // アコーディオン要素関数
  if(document.querySelectorAll('.js_accordionTrigger').length){
    doAccordion();
  }

  if(document.querySelectorAll('#tabControl').length){
    activeTabMenu();
  }

  if(document.querySelectorAll('#childTabControl').length){
    activeChildTabMenu();
  }


  elm_wrapper.style.marginTop = HEADER_H + "px"; // headerの高さの値をwrapperにmargin-topとして付与
  checkUA.addUAClass();                          // UAおよびデバイスクラス追加処理
  setSmoothScroll(moveOutHeader);                //スムーススクロール
  doFormInputCompletion();                       //フォーム項目補完処理
  setWrapperCtaHeightBuffer();                   //Splide初期化関数
  activeReasonCardSpSlider();                    //Splide初期化関数



})

// ------------------------------------------------------------
// window読み込み時
// ------------------------------------------------------------

window.addEventListener('load', () => {
  elm_wrapper.style.marginTop = HEADER_H + "px"; // headerの高さの値をwrapperにmargin-topとして付与
  offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');  // オフキャンバス処理
  textSizeFit();                                 //テキストサイズ計算
  countFormRequired();                           //フォーム必須項目カウンター
  setGsapCountFormRequired();                    //フォーム必須項目カウンター表示/非表示
});

// ------------------------------------------------------------
// イベント：スクロール
// ------------------------------------------------------------

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.scrollX;
  CURRENT_SC_OFFSET_Y = window.scrollY;

  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  //pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
  doScrollDirectionFlexibleHeaderFixed();
  pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
})

// ------------------------------------------------------------
// イベント：リサイズ
// ------------------------------------------------------------
window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
  elm_wrapper.style.marginTop = HEADER_H + "px"; // headerの高さの値をwrapperにmargin-topとして付与
})





// ===============================================================================
// TODO:README
// 全ページ共通関数
// 全ページに共通する処理は以下に書いてください
// ===============================================================================

// ------------------------------------------------------------
// スムーススクロール
// ------------------------------------------------------------

function setSmoothScroll(cb){

  const anchors = document.querySelectorAll('a[href^="#"]');
  const bufferH = 0;
  const urlHash = location.hash; // URLのアンカー（#以降の部分）を取得

  // 各 anchor にクリックイベント
  for ( let i = 0; i < anchors.length; i++ ) {

    anchors[i].addEventListener('click', (e) => {

      // クリックした要素が `.js_exclusive` クラスを持っているか確認
      if (!event.target.closest('.js_exclusive')) {

        e.preventDefault();  //デフォルトのクリックイベント無効化

        // 各 anchor の href属性取得
        const href= anchors[i].getAttribute("href");


        // topに戻る以外のアンカーリンク
        // ------------------------------------------------------------
        if (href !== '#top') {

          // スクロール先の要素を取得 （アンカーの リンク先 #.. の # を取り除いた名前と一致する id名の要素）
          const target = document.getElementById(href.replace('#', ''));
          // 対象要素のwindowに対するY軸の値を取得
          const targetOffsetY = target.getBoundingClientRect().top + window.scrollY - bufferH;

          // スクロール先の要素の位置を取得
          // header の高さ引く
          const position = window.pageYOffset + target.getBoundingClientRect().top - bufferH;

          // スクロールアニメーション
          window.scroll({
            top: position,
            behavior: 'smooth'
          });

          // スクロール完了をチェック、完了後にcallback関数を実行
          // -------------------
          let checkCompleteScroll = setInterval(() => {
            if(CURRENT_SC_OFFSET_Y < targetOffsetY){
              cb();
              clearInterval(checkCompleteScroll);
            }
          }, 30);



        // topに戻るアンカーリンク
        // ------------------------------------------------------------
        } else {
          // スクロールアニメーション
          window.scroll({
            top: 0,
            behavior: 'smooth'    // スクロールアニメーション
          });

        }
      }

    });
  }

}


// ------------------------------------------------------------
// Windowのスクロール量に応じてヘッダーの表示/非表示を切り替える処理
// ------------------------------------------------------------

let targetMv_H = 0;
let threshold = HEADER_H + targetMv_H;

function doScrollDirectionFlexibleHeaderFixed() {

  if(!document.querySelector(".js_target_mv") === null){
    targetMv_H = document.querySelector(".js_target_mv").offsetHeight;
    threshold = HEADER_H + targetMv_H;
  }

  if(CURRENT_SC_OFFSET_Y > threshold){
    elm_header.classList.add("moveOut");
    elm_header.classList.remove("moveIn");
  }

  if(CURRENT_SC_OFFSET_Y < LAST_SCROLL_POS){
    elm_header.classList.remove("moveOut");
    elm_header.classList.add("moveIn");
  }

  LAST_SCROLL_POS = CURRENT_SC_OFFSET_Y;

}

const moveOutHeader = function(){
  elm_header.classList.add("moveOut");
  elm_header.classList.remove("moveIn");
}

// ------------------------------------------------------------
// 画面固定のCTAがある場合Wrapperの下部に余白をつける
// ------------------------------------------------------------
function setWrapperCtaHeightBuffer(){
  let ctaUnit = document.getElementById("js_fixed_floatingCtaUnit");
  if (ctaUnit && WIN_WIDTH <= 768) {
    let cta_H = ctaUnit.getBoundingClientRect().height;
    let wrapper = document.getElementById("wrapper");
    if (wrapper) {
      wrapper.style.paddingBottom = cta_H + "px";
    }
  }
}

// ------------------------------------------------------------
// MVフォームのinput入力補完機能
// ------------------------------------------------------------

function doFormInputCompletion(){

    if($('.js_formInputCompletion').length > 0){

    $('input[name^="field_mv_"]').each(function() {

      var fieldName = $(this).attr('name');
      var fieldNumber = fieldName.split('_').pop(); // field_mv_から数字部分を取得
      var storedValue = localStorage.getItem('pw_set_fieldVal_' + fieldNumber);

      if (storedValue) {
        $(this).val(storedValue); // ローカルストレージに値があればセット
      }
    });

    countFormRequired(); //必須カウンターを起動


    // 各inputの変更時にローカルストレージに値を保存
    $('input[name^="field_mv_"]').on('input', function() {
      var fieldName = $(this).attr('name');
      var fieldNumber = fieldName.split('_').pop(); // field_mv_から数字部分を取得
      var fieldValue = $(this).val();

      localStorage.setItem('pw_set_fieldVal_' + fieldNumber, fieldValue); // ローカルストレージに保存
    });
  }
}

// 入力補完機能の記録をクリア
// ----------------------------

$(function(){
  $('.js_clearFormInputCompletion').on('click', function() {
    localStorage.removeItem('pw_set_fieldVal_1');
    localStorage.removeItem('pw_set_fieldVal_2');
    localStorage.removeItem('pw_set_fieldVal_3');
    localStorage.removeItem('pw_set_fieldVal_4');
  });
});

// ------------------------------------------------------------
// 文字サイズを親要素の横幅に応じて自動計算させる
// ------------------------------------------------------------

function textSizeFit(){

  if($('.js_textSizeFit').length){

    $('.js_textSizeFit').each(function() {

      let element = $(this);

      let maxWidth = 120; // 横幅170px

      if($("body").hasClass("isSP")){
        maxWidth = 120; // 横幅170px
      }

      let fontSize = parseInt(element.css('font-size'), 10);
      // 文字が折り返すまでフォントサイズを小さくする
      while (element.width() >= maxWidth && fontSize > 1) {

        fontSize--;
        element.css('font-size', fontSize + 'px');

      }
    });
  }

}



// ===============================================================================
// TODO:README
// コンポーネント単位の関数
//
// ===============================================================================



// ------------------------------------------------------------
// アコーディオンメニューの設定 ※jquery使用
// ------------------------------------------------------------
function doAccordion(){
  $(function (){
    $('.js_accordionTrigger').click(function(){

      let $this = $(this);
      let className = "js_Active";

      if($this.hasClass(className)){
        $this.removeClass(className);
        $this.addClass("isArrowBottom");
        $this.removeClass("isArrowTop");
        $this.text("流れを確認する");

      }else{
        $this.addClass(className);
        $this.addClass("isArrowTop");
        $this.removeClass("isArrowBottom");
        $this.text("流れを閉じる");
      }
      $this.prev('.js_accordionTarget').slideToggle();

    });
  });
}

// ------------------------------------------------------------
// タブメニュー
// ------------------------------------------------------------
function activeTabMenu(){
  // ---------------------------
  // ▼A：対象要素を得る
  // ---------------------------
  let tabs = document.getElementById('tabControl').getElementsByTagName('a');
  let pages = document.getElementsByClassName('js_target_tab'); // クラス名で選択する

  // ---------------------------
  // ▼B：タブの切り替え処理
  // ---------------------------
  function changeTab() {
    // ▼B-1. href属性値から対象のid名を抜き出す
    let targetId = this.href.substring(this.href.indexOf('#')+1,this.href.length);

    // ▼B-2. 指定のタブページだけを表示する
    for(let i=0; i<pages.length; i++) {
      if( pages[i].id != targetId ) {
        pages[i].style.display = "none";
      }
      else {
        pages[i].style.display = "block";
      }
    }

    // ▼B-3. クリックされたタブを前面に表示する
    for(let i=0; i<tabs.length; i++) {
      tabs[i].classList.remove("is_select")
    }
    this.classList.add("is_select")

    // ▼B-4. ページ遷移しないようにfalseを返す
    return false;
  }

  // ---------------------------
  // ▼C：すべてのタブに対して、クリック時にchangeTab関数が実行されるよう指定する
  // ---------------------------
  for(let i=0; i<tabs.length; i++) {
    tabs[i].onclick = changeTab;
  }

  // ---------------------------
  // ▼D：最初は先頭のタブを選択しておく
  // ---------------------------
  tabs[0].onclick();
}

// ------------------------------------------------------------
// タブメニューの中の子タブメニュー
// ------------------------------------------------------------

function activeChildTabMenu(){
  // ---------------------------
  // ▼A：要素取得
  // ---------------------------
  let childPages = document.getElementsByClassName('js_target_childTab'); // クラス名で選択する
  let childTabs = document.getElementsByClassName('js_childTabMenuTriger');

  // ---------------------------
  // ▼A：関数読み込み時処理（タブ1番をアクティブに）
  //     さらに全てのタブ要素にイベントを定義
  // ---------------------------

  childTabs[0].classList.add("is_select" , "is_active");
  childPages[0].style.display = "block";

  for(let i= 0; i < childPages.length; i++) {
    childTabs[i].onclick = changeChildTab;
    if(i != 0){
      childPages[i].style.display = "none";
    }
  }

  // ---------------------------
  // ▼B：関数定義
  //     クリックイベントによるタブの切り替え処理
  // ---------------------------
  function changeChildTab() {

    // ▼B-1. href属性値から対象のid名を抜き出す
    let targetId = this.href.substring(this.href.indexOf('#')+1,this.href.length);

    // ▼B-2. 指定のタブページだけを表示する（タブとページは同一個数が出力されるという前提がある）
    for(let i= 0; i < childPages.length; i++) {
      if( childPages[i].id != targetId ) {
        childPages[i].style.display = "none";
        childTabs[i].classList.remove("is_select" , "is_active")
      }
      else {
        childPages[i].style.display = "block";
        childTabs[i].classList.add("is_select" , "is_active")
      }
    }
    // ▼B-4. ページ遷移しないようにfalseを返す
    textSizeFit(); //切り替え時にテキストサイズ再計算
    return false;
  }
}



// ===============================================================================
// TODO:README
// トップページ固有関数
//
// ===============================================================================
let banner_slide;

if (document.querySelector('.topBanner') !== null) {
  banner_slide = new Splide('.topBanner', {
    type: 'loop',
    height: '10rem',
    padding: { left: 0, right: 0 },
    wheel: true,
    perPage: 3,
    arrows: true,
    arrowPath: 'M13.3748 36.6668L10.4165 33.7085L24.1248 20.0002L10.4165 6.29183L13.3748 3.3335L30.0415 20.0002L13.3748 36.6668Z',
    pagination: true,
    gap: 20,
    classes:{
      pagination: 'splide__pagination topBanner_pagination',
      page: 'splide__pagination__page topBanner_page',
      arrows: 'splide__arrows  topBanner_arrows',
      arrow : 'splide__arrow topBanner_arrow',
      prev  : 'splide__arrow--prev topBanner_arrow_prev',
      next  : 'splide__arrow--next topBanner_arrow_next',
    },
    // breakpoints:{
    //   1024:{
    //     perPage:2,
    //   }
    // }
  });

  function activeBannerSplideSlider() {
    // スライド要素を取得
    let slides = document.querySelectorAll('.topBanner .splide__slide');
    let elm_banners = document.querySelectorAll('.topBanner');

    if (elm_banners.length > 0) {
      // li 要素が4つ以上あるか確認
      if (slides.length >= 4) {
        // Splide を初期化してマウント
        banner_slide.mount();
      } else {
        // 4つ以下の場合はSplideを初期化せず、各要素からクラスを削除
        elm_banners.forEach(elm => elm.classList.remove("splide"));
        // static表示用のクラスを追加
        elm_banners.forEach(elm => elm.classList.add("static_topBanner"));
      }
    }
  }
  activeBannerSplideSlider();
}

function activeReasonCardSpSlider() {
  if(document.querySelector('.top_reasonCard_spSlider') !== null){
    let reason_slider = new Splide('.top_reasonCard_spSlider' ,{
      perPage: 1,
      perMove: 1,
      gap: '20px',
      arrows: false,
      pagination: false,
      wheel: true,
      padding : {right:'6.78571429rem'},
      releaseWheel : true,
      drag: 'free',
      snap: true,
    })
    reason_slider.mount();
  }
}

// ============================================================
// フォーム関係
// ============================================================
$('input').on('input', function() {
  if($(".js_requiredCounterTarget").length) {
    countFormRequired();
  }
});

function countFormRequired(){
  if($(".js_requiredCounterTarget").length){

    let $target = $(".js_requiredCounterTarget");
    let $targetIndex = $target.length;
    let error_i = $targetIndex;

    //必須項目のトータル数をパネルに挿入
    $(".js_requiredCounterTotal").text($targetIndex);

    //各必須項目チェック処理
    // -------------------
    for(let i = 0 ; i < $targetIndex ; i++){

      let $this_i = $($target[i]);

      if(!($this_i.val())){
        //inputにそもそも値が入っていない場合
      }else {
        //inputに値が入っている場合
        if (!($this_i.hasClass("js_validEmail"))){
          error_i--;
        }else{
          //メールアドレス項目の場合
          if (isValidEmail($this_i.val())) {
            error_i--;
          } else {
            error_i++;
            $this_i.addClass("invalid")
          }
        }
      }
    }

    //エラー件数を挿入
    $(".js_requiredCounterCurrent").text(error_i)

    // 必須項目数表示パネルを更新
    if(error_i === 0){
      $(".js_requiredCounterPanel").addClass("js_isSuccess");
      $(".js_requiredCounterTextinfo").addClass("js_isSuccess");
      toggleSubmitBtn(true);
    }else{
      $(".js_requiredCounterPanel").removeClass("js_isSuccess");
      $(".js_requiredCounterTextinfo").removeClass("js_isSuccess");
      toggleSubmitBtn(false);
    }
  }
}


// ------------------------------------------------------------
// メールアドレスチェック
// ------------------------------------------------------------
function isValidEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

// ------------------------------------------------------------
// チェックボックス
// ------------------------------------------------------------

activeCheckboxChecked();

function activeCheckboxChecked(){
  if($(".js_activeCheckboxCheckedTrigger").length){

    doActiveCheckboxChecked(); //ドキュメント読み込み時に実行

    $('.js_activeCheckboxCheckedTrigger input[type="checkbox"]').on('change', function() {
      doActiveCheckboxChecked();
    });

    function doActiveCheckboxChecked(){
      // チェックされたチェックボックスの index 番号を取得
      const $checkboxes = $('.js_activeCheckboxCheckedTrigger input[type="checkbox"]');
      const $targetInput = $('.js_activeCheckboxCheckedTarget');

      // 現在の value を配列に変換
      let currentValues = $targetInput.val().split(',').filter(v => v);

      $checkboxes.each(function(index) {

        if ($(this).is(':checked')) {

          // チェックされている場合、index を追加（重複を避ける）
          if (!currentValues.includes(String(index))) {
            currentValues.push(String(index));
          }
        } else {
          // チェックが外れた場合、index を削除
          currentValues = currentValues.filter(value => value !== String(index));
        }
      });

      // 配列を文字列にして再度 value に設定
      $targetInput.val(currentValues.join(','));

      countFormRequired();
    }
  }
}

// ------------------------------------------------------------
// 送信ボタンdisable処理
// ------------------------------------------------------------
function toggleSubmitBtn(bool){
  if($(".js_checkRequiredSubmit").length){
    const $this = $(".js_checkRequiredSubmit");
    if(bool === true){
      $this.removeClass("js_isDisable");
    }else{
      $this.addClass("js_isDisable");
    }
  }
}

// ------------------------------------------------------------
// フォームが画面領域に入ったらパネルを表示&footerが画面内に入ったら要素をずらす
// ------------------------------------------------------------

function setGsapCountFormRequired(){
  // GSAP ScrollTriggerを使用して、form_blockが画面に入ったらフェードイン
  if($('.js_requiredCounterPanel').length){
    gsap.to('.js_requiredCounterPanel', {
      opacity: 1,
      duration: .3,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '#formBody',
        start: 'top bottom',
        toggleActions: 'play none none reverse',
      }
    });
    ScrollTrigger.create({
      trigger: '.footer',
      start: 'top bottom', // footer が画面に入ったら
      onEnter: () => {
        const footerHeight = document.querySelector('.footer').offsetHeight;
        gsap.to('.js_requiredCounterPanel', {
          y: -footerHeight, // footer の高さ分だけ上にずらす
          duration: 0.5,
          ease: 'power2.out'
        });
      },
      onLeaveBack: () => {
        // スクロールが戻ったときに元に戻す
        gsap.to('.js_requiredCounterPanel', {
          y: 0,
          duration: 0.5,
          ease: 'power2.out'
        });
      }
    });
  }
}


//
//
//
//
//
//
//
//
//
//
//
//
//
//

// ===============================================================================
// TODO:README
// a blog cms組み込み/標準JSの上書き関する関数上書き
//
// ===============================================================================

ACMS.Ready(function () {
  // ACMS.Config.LiteEditorConf.btnOptions.push({
  //   label: '打ち消し',
  //   tag: 's',
  //   className: '',
  //   sampleText: ''
  // });
  ACMS.Config.aTableOption = [];
  ACMS.Config.aTableOption.push(
    {
      label: 'テーブル（枠線のみ&スマホ時1カラム）',
      value: 'table01'
    },{
      label: 'テーブル（枠線&th背景色あり）',
      value: 'table03'
    },{
      label: 'テーブル（枠線&th背景色あり）レスポンシブテーブル',
      value: 'table03Responsive'
    },{
      label: 'スクロールを催すテーブル（横スクロールテーブル）',
      value: 'tableScrollX js-table-unit-scroll-hint'
    }
    // TODO : BANZAI : MEMO
    // js-table-unit-scroll-hintクラスをセットすることで
    // tableタグをラップするdiv要素にスクロールヒントのクラスがつく
  );
  //ACMS.Config.unitGroupAlign  = false;
});

// ------------------------------------------------------------
// unitの拡張フィールドで指定した値をclassにセットする
// ------------------------------------------------------------

$("[data-class]").each(function() {
  // data-class属性の値を取得
  var dataClassValue = $(this).data("class");

  // 取得した値をclassに追加
  $(this).addClass(dataClassValue);
});


