import * as func from './_function';

export function setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,bodyElmIdName){

  let rootElm                   = document.getElementById(bodyElmIdName);
  let offCanvas                 = document.getElementById('offcanvas');
  let current_sc_offset_y       = window.pageYOffset;
  let beforScOffsetY;

  // offcanvasBgの生成処理
  // ============================================================
  if(!func.isset(document.getElementById('offcanvasBg'))){
    let bgLayerElm = document.createElement('div');
    offCanvas.insertAdjacentHTML('afterend', '<div id=\"offcanvasBg\" class=\"offcanvasBg\"></div>')
  }

  // offcanvas開閉処理
  // ============================================================
  document.getElementById('offcanvasTrigger').addEventListener('click', () => {

    if(window.innerWidth <= BLAKEPOINT_HEADER_SP_MODE){

      // 制御処理
      // -------------------
      if (rootElm.classList.contains('js_isOffcanvasActive')) {
        rootElm.classList.remove('js_isOffcanvasActive');
        setBodyFixed(false);

      } else {
        rootElm.classList.add('js_isOffcanvasActive');
        setBodyFixed(true);
      }

      // 動的に追加される offcanvasBg 要素のクリックイベント処理
      // -------------------
      document.getElementById('offcanvasBg').addEventListener('click',() => {
        rootElm.classList.remove('js_isOffcanvasActive');
        setBodyFixed(false)
      });

    }

  });

  // リサイズ時のリセット処理
  // ============================================================

  window.addEventListener('resize',() => {
    if(window.innerWidth > BLAKEPOINT_HEADER_SP_MODE){
      rootElm.classList.remove('js_isOffcanvasActive');
    }
  });

  // body固定処理
  // ============================================================

  window.addEventListener('scroll', () => {
    current_sc_offset_y  = window.pageYOffset;
  })

  function setBodyFixed(mode){

    if(mode === true){

      beforScOffsetY = current_sc_offset_y;
      rootElm.style.position = "fixed";
      document.body.style.top = current_sc_offset_y * -1 + 'px';


    }else{

      rootElm.style.position = '';
      document.body.style.top = ''
      window.scrollTo(0, beforScOffsetY)


    }

  }



}
